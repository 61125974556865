import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function App() {
  const [formVisible, setFormVisible] = useState(false);
  const [mail, setMail] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [placeholderMail, setPlaceholderMail] = useState(
    "saisissez votre adresse mail"
  );

  const resetForm = () => {
    setMail("");
    setPasswordInput("");
    setPlaceholderMail("saisissez votre adresse mail");
    setPlaceholderPassword("saisissez un mot de passe");
  };

  const [placeholderPassword, setPlaceholderPassword] = useState(
    "saisissez un mot de passe"
  );

  const placeholderChangeMail = () => {
    if (placeholderMail === "saisissez votre adresse mail") {
      setPlaceholderMail("");
    }
  };

  const placeholderChangePassword = () => {
    if (placeholderPassword === "saisissez un mot de passe") {
      setPlaceholderPassword("");
    }
  };
  const onClick = () => {
  
    setFormVisible(true);
    resetForm()
  };
  const onClickHiden = () => {
    setFormVisible(false);
   
  };

  const handleSubmit = () => {
    console.log = "ok";
  };

  const handlePasswordChange = (e) => {
    setPasswordInput(e.target.value);
  };

  const handleMailChange = (e) => {
    setMail(e.target.value);
  };

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className=" background  ">
      <div className={`formulaire p-10 absolute  ${formVisible ? "" : "hidden"}`}>
        <div className="flex justify-center items-center flex-col ">
          <div>
            <img src="/lo.png" alt="logo" />
          </div>
       
            <p className="title mt-4">
               Créer votre compte pour accéder à l'intégralité du contenu de
              notre site web
            </p>

            <form
              className="flex justify-center items-center flex-col space-y-10"
              onSubmit={handleSubmit}
            >
              <div className=" w-full" style={{borderBottom:"#8F8F8F 1px solid"}}>
                <label name="identifiant">
                  <p className="mt-4 mb-4" style={{color: "#8F8F8F",}}>identifiant  </p>

                  <input
                    type="email"
                    value={mail}
                    placeholder={placeholderMail}
                    onChange={handleMailChange}
                    onClick={placeholderChangeMail}
                  />
                </label>
              </div>
              <div className="border-b w-full "style={{borderBottom:"#8F8F8F 1px solid"}} >
                <label className="relative">
                  <p className="mb-4" style={{color: "#8F8F8F",}}>mot de passe </p>
                  <input
                    onChange={handlePasswordChange}
                    type={passwordType}
                    value={passwordInput}
                    placeholder={placeholderPassword}
                    onClick={placeholderChangePassword}

                  />
                  <button className="absolute right-1  " onClick={togglePassword}>
                    {passwordType === "password" ? (
                      <FontAwesomeIcon  style={{color: "#8F8F8F",}} icon={faEyeSlash} />
                    ) : (
                      <FontAwesomeIcon  style={{color: "#8F8F8F",}} icon={faEye} />
                    )}
                  </button>
                </label>
              </div>

           

              <div className="flex w-full justify-between items-end">
                <button type="button" className="annuler  " onClick={onClickHiden}>
                 ANNULER
                </button>
                <button className="enregistrer " type="submit">
               ENREGISTRER
                </button>
              </div>
            </form>
          
        </div>
      </div>
      <button onClick={onClick} className=" m-auto bg-blue-500 text-white p-4">
        click
      </button>
    </div>
  );
}

export default App;
